import { HeaderClassParams, SortModelItem } from "ag-grid-community";
import { QueryDslQueryContainer, SearchRequest, Sort } from "@elastic/elasticsearch/lib/api/types";
// This is utility to auto size all columns in grid
// Ag Grid provide autoSizeAllColumns utils but we will wanted to keep last column full width so this is custom utility
export const dataGridAutoSizeColumns = (params, skipHeader?) => {
  const allColumnIds = [];
  const columns = params.columnApi.getColumns();
  columns.forEach((column, i) => {
    if (column.length !== i + 1) {
      allColumnIds.push(column.getId());
    }
  });
  params.api.autoSizeColumns(allColumnIds, !!skipHeader);
};

export const headerClassWithHighlight = (params: HeaderClassParams, cls?: string) => {
  const focused = params.api.getFocusedCell()?.column?.getColId() === params.column.getColId();
  return focused ? `highlight-with-primary ${cls}` : cls;
};

export const columnTypes = {
  rightAlignedHeaderHighlight: {
    headerClass: (params: HeaderClassParams) =>
      headerClassWithHighlight(params, "ag-right-aligned-header")
  },
  headerHighlight: {
    headerClass: (params: HeaderClassParams) => headerClassWithHighlight(params)
  }
};

export const getDataGridSortModel = (
  sortModel: SortModelItem[],
  skipKeywordList: string[] = [],
  sortConfig: { [key: string]: string } = {}
) => {
  return sortModel.map((data) => {
    const key = sortConfig[data.colId] || data.colId;
    return {
      [`${key}${!skipKeywordList.includes(key) ? ".keyword" : ""}`]: {
        order: data.sort,
        unmapped_type: "string"
      }
    };
  });
};

export const escapeSpecialCharacters = (s: string) => {
  let result = "";
  for (let i = 0; i < s.length; i++) {
    const c = s.charAt(i);
    // These characters are part of the query syntax and must be escaped
    if (
      c === "\\" ||
      c === "+" ||
      c === "-" ||
      c === "!" ||
      c === "(" ||
      c === ")" ||
      c === ":" ||
      c === "^" ||
      c === "[" ||
      c === "]" ||
      c === '"' ||
      c === "{" ||
      c === "}" ||
      c === "~" ||
      c === "*" ||
      c === "?" ||
      c === "|" ||
      c === "&" ||
      c === "/"
    ) {
      result = result.concat("\\");
    } else if (c !== ">" && c !== "<") {
      // removed from string
      result = result.concat(c);
    }
  }
  return result.toString();
};

type buildSearchPayloadParams = {
  searchTerm: string;
  filter?: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  sortModel?: Sort;
  options?: {
    from?: number;
    size?: number;
  };
};

/**
 * Takes common search function input structure and converts it to
 * a pattern we use for elasticsearch search payloads.
 */
export function buildSearchPayload({
  searchTerm,
  filter,
  sortModel,
  options
}: buildSearchPayloadParams) {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: null,
        must_not: [],
        filter: []
      }
    },
    sort: sortModel
  };

  if (searchTerm) {
    searchBody.query.bool.must = {
      multi_match: {
        query: escapeSpecialCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["*"]
      }
    };
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          terms: { [`${key}.keyword`]: value }
        });
      } else if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  return searchBody;
}
