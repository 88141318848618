import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { Operation, compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import {
  DataSourceListResponse,
  DataSource,
  DataSourceResponse
} from "../../types/accelerator/data-source";
import { escapeCharacters, urlPathWithQueryParams } from "../../utils";
import { SearchApiParams } from "../../types";

/**
 *  Get DataSource
 * @param {string} searchTerm
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<DataSourceListResponse>}
 */
const searchDataSources = async ({
  searchTerm,
  filter,
  sortModel,
  options
}: SearchApiParams<DataSource>): Promise<DataSourceListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: [],
        filter: []
      }
    },
    sort: sortModel
  };

  if (searchTerm) {
    searchBody.query.bool.must = {
      multi_match: {
        query: escapeCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["name", "description"]
      }
    };
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          terms: { [`${key}.keyword`]: value }
        });
      } else if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  return await acceleratorApi.post("datasources/search", searchBody);
};

/**
 * Get DataSource
 *
 * @return {Promise<DataSourceListResponse>}
 */
export const getDataSources = async (): Promise<DataSourceListResponse> => {
  return acceleratorApi.get<DataSourceListResponse>(
    urlPathWithQueryParams("datasources", { page: 0, size: 1000 })
  );
};

/**
 * Get DataSource By ID
 *
 * @param dataSourceId: @ String
 *
 * @return {Promise<DataSourceResponse>}
 */
export const getDataSourceById = async (dataSourceId: string): Promise<DataSourceResponse> => {
  return acceleratorApi.get<DataSourceResponse>(`datasources/${dataSourceId}`);
};

/**
 * Create DataSource
 *
 * @param dataSource: @type Partial<DataSource>
 *
 * @return {Promise<DataSource>}
 */
export const createDataSource = async (
  dataSource: Partial<DataSource>
): Promise<DataSourceResponse> => {
  return acceleratorApi.post<Partial<DataSource>, DataSourceResponse>("datasources", dataSource);
};

/**
 * Patch DataSource
 * @param previousValue: @type DataSource
 * @param nextValue: @type Partial<DataSource>
 *
 * @return {Promise<DataSourceResponse>}
 */
export const patchDataSource = async (
  previousValue: DataSource,
  nextValue: Partial<DataSource>
) => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch<Operation[], DataSourceResponse>(
    `datasources/${previousValue.id}`,
    patchUpdates
  );
};

/**
 * Delete Asset
 *
 * @param dataSourceId: @ String
 *
 * @return {Promise<void>}
 */
export const deleteDataSource = async (dataSourceId: string): Promise<void> => {
  await acceleratorApi.delete(`datasources/${dataSourceId}`);
};

export const DataSourceApi = {
  searchDataSources,
  getDataSources,
  getDataSourceById,
  createDataSource,
  patchDataSource,
  deleteDataSource
};
