import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, PageContainer, WithLoader } from "@packages/theme-mui-v5";

import {
  DataGrid,
  ColDef,
  ICellRendererParams,
  AgGridReactProps,
  RowActionsCell
} from "@packages/data-grid";

import useLocalState from "../hooks/useLocalState";
import { Group, useGetGroupsByLocationId } from "@packages/service-api";

export let gridRef = React.createRef<AgGridReactProps<Group>>();

const GroupsTable = () => {
  const navigate = useNavigate();
  const { smUp } = useMediaQuery();
  const { applicationId, selectedLocationId } = useLocalState();
  const { groups, loading } = useGetGroupsByLocationId(applicationId, selectedLocationId);

  React.useEffect(() => {
    return () => {
      gridRef = null;
    };
  }, []);

  const columnDefs: ColDef[] = React.useMemo(() => {
    return [
      { field: "groupName", headerName: "Name" },
      ...(smUp ? [{ field: "description", headerName: "Description" }] : []),
      {
        field: "groupId",
        headerName: "",
        maxWidth: 200,
        minWidth: 200,
        cellClass: "align-center",
        cellRenderer: (params: ICellRendererParams) => (
          <RowActionsCell
            actions={[
              {
                icon: "groups",
                color: "primary",
                tooltip: "Manage Users",
                action: () => navigate(`/admin/auth/groups/${params.value}/manage-users`)
              },
              {
                icon: "edit",
                color: "primary",
                tooltip: "Edit Details",
                action: () => navigate(`/admin/auth/groups/${params.value}/edit`)
              },
              {
                icon: "key",
                color: "primary",
                tooltip: "Edit Permissions",
                action: () => navigate(`/admin/auth/groups/${params.value}/edit-permissions`)
              },
              {
                icon: "delete",
                color: "error",
                tooltip: "Delete",
                action: () => navigate(`/admin/auth/groups/${params.value}/delete`)
              }
            ]}
          />
        )
      }
    ];
  }, [smUp]);

  return (
    <PageContainer hideToolbars>
      <WithLoader loading={loading}>
        <DataGrid rowData={groups} columnDefs={columnDefs} gridRef={gridRef} loading={loading} />
      </WithLoader>
    </PageContainer>
  );
};
export default GroupsTable;
