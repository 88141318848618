import { config } from "@nutrien-operations/config";

import { ApiInstance } from "../api-instance";

import { NodesApi } from "./nodes";
import { FormulasApi } from "./formula";
import { ProductsApi } from "./products";
import { ProductBalancesApi } from "./product-balances";
import { TungstenApi } from "./tungsten";
import { SiteSettingsApi } from "./site-settings";
import { CsvTemplateApi } from "./csv-templates";

// Authenticated API connection wrapper for the Accelerator Api
export class MaterialBalanceApi {
  protected _instance: ApiInstance | null = null;

  constructor() {
    const prefixUrl = config.API["material-balance"];
    this._instance = new ApiInstance(prefixUrl);
  }

  getInstance = () => this._instance;

  static Nodes = NodesApi;
  static Formulas = FormulasApi;
  static Products = ProductsApi;
  static ProductBalances = ProductBalancesApi;
  static Tungsten = TungstenApi;
  static SiteSettings = SiteSettingsApi;
  static CsvTemplates = CsvTemplateApi;
}

export const materialBalanceApi = new MaterialBalanceApi().getInstance();
