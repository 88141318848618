import { Amplify, Auth, Hub } from "aws-amplify";
import { applyPatch, compare } from "fast-json-patch";

import { config, applications } from "@nutrien-operations/config";
import MessageBus from "@packages/message-bus";

import { Preferences, UserPermissionsMap, UserProfile } from "../types";

export class Authentication {
  private _userProfile: UserProfile;
  private _userPermissions: UserPermissionsMap = {};
  isAuthenticated = false;
  public isImpersonationActive = false;
  public impersonationExpiryTime = null;
  initialized = false;

  constructor() {
    this.setupListeners();
  }

  init = (authConfig) => {
    Amplify.configure(authConfig);
    this.initialized = true;
  };

  public get userProfile() {
    return this._userProfile;
  }

  private set userProfile(userProfile: UserProfile) {
    if (userProfile?.impersonating?.profile) {
      this._userProfile = userProfile?.impersonating?.profile;
      this.isImpersonationActive = true;
      this.impersonationExpiryTime = userProfile?.impersonating?.expiryTime;
    } else {
      this.isImpersonationActive = false;
      this._userProfile = userProfile;
      this.impersonationExpiryTime = null;
    }

    this.generatePermissionsMap();
    MessageBus.publish("auth.profile", this.userProfile);
  }

  public get userPermissions() {
    return this._userPermissions;
  }

  private set userPermissions(permissions: UserPermissionsMap) {
    this._userPermissions = permissions;
    MessageBus.publish("auth.permissions", this.userPermissions);
  }

  /**
   * Complete a partial update to user preferences
   * @param updates {Partial<Preferences>} - updates to the preferences object
   */
  public async updateUserPreferences(updates: Partial<Preferences>) {
    try {
      const patch = compare(this.userProfile.preferences, {
        ...this.userProfile.preferences,
        ...updates
      });

      await fetch(`${config.API["common-data"]}me/preferences`, {
        method: "PATCH",
        body: JSON.stringify(patch),
        headers: {
          Authorization: await this.getAccessToken()
        }
      });

      // If impersonation is active, we need to refetch the full user profile in order to correctly re-determine the impersonation state
      // after the impersonated user's preferences get updated.
      if (this.isImpersonationActive) {
        await this.fetchUserProfile();
      } else {
        const result = applyPatch(this.userProfile.preferences, patch);

        this.userProfile = {
          ...this.userProfile,
          preferences: result.newDocument
        };
      }
    } catch (error) {
      throw new Error("Failed to update user preferences");
    }
  }

  generatePermissionsMap = () => {
    let nextMap = {};

    if (Array.isArray(this.userProfile?.permissions)) {
      nextMap = this.userProfile.permissions.reduce((map, permission) => {
        if (!map[permission.applicationId]) {
          map[permission.applicationId] = {};
        }

        map[permission.applicationId][permission.permissionTypeId] = permission.locationIds;

        return map;
      }, {});
    }

    this.userPermissions = nextMap;
  };

  signOut = () => {
    Auth.signOut({ global: true });
    this.isAuthenticated = false;
    this.userProfile = undefined;
    this.userPermissions = {};

    MessageBus.publish("auth.signout", { success: true });
  };

  signIn = () => {
    if (!this.initialized) {
      throw new Error("Authentication has not been initialized");
    }

    Auth.federatedSignIn({
      provider: config.Amplify.Auth.adfsFederatedIdentityProviderId
      // this is typed as any as an undocumented feature of federatedSignIn
      // is if you use provider rather than customProvider it allows you to
      // bypass the hosted UI auth screen and be directed straight to AD for auth
      // eslint-disable-next-line
    } as any);
  };

  getSession = async () => {
    try {
      return await Auth.currentSession();
    } catch (error) {
      return null;
    }
  };

  getAccessToken = async () => {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  };

  /**
   * Check if a user has permission(s) to an application at a particular site
   * @param applicationId Application Id
   * @param permissionTypeIds Array of PermissionTypeId to check if user has assigned
   * @param siteId Site Id
   * @param locationId Optional location Id to check for a permission at the defined site
   * @param skipSiteLocationCheck Optional explicity skip the site/location check. Usefully for when you want to see if a user has some level of access to an application
   * @returns {boolean}
   */
  checkPermission = (options: {
    applicationId: string;
    permissions: string[];
    siteId?: string | null;
    locationId?: string;
    skipSiteLocationCheck?: boolean;
  }): boolean => {
    const {
      applicationId,
      permissions,
      siteId,
      locationId,
      skipSiteLocationCheck = false
    } = options;

    if (!applicationId) {
      throw new Error("applicationId required");
    }

    if (
      !Array.isArray(permissions) ||
      permissions.length === 0 ||
      permissions.includes(undefined)
    ) {
      throw new Error("You must provide at least one permissionTypeId");
    }

    if (!siteId && !locationId && !skipSiteLocationCheck) {
      throw new Error(
        "You must explicity opt to skip the site/location check by passing skipSiteLocationCheck=true"
      );
    }

    if (!this.isAuthenticated || !this._userProfile) {
      return false;
    }

    if (this.userProfile.isSysAdmin) {
      return true;
    }

    let matchingPermission;

    for (const permissionTypeId of permissions) {
      const match = this._userProfile.permissions.find((permission) => {
        const permissionTypeMatches = permission.permissionTypeId === permissionTypeId;
        const applicationMatches = permission.applicationId === applicationId;

        // permissionType and applicationId must always match
        if (!permissionTypeMatches || !applicationMatches) {
          return false;
        }

        if (skipSiteLocationCheck) {
          return true;
        }

        let hasAccess = false;

        // Perform check for location level permission. If a locationId is provided this takes priority over a siteId check
        if (locationId) {
          hasAccess = permission.locationIds.some((id) => id === locationId);
        } else if (siteId) {
          // If we don't need to test a specific location, but need to check a user has access somewhere at the site, we test to see if the current permission matches the siteId param.
          hasAccess = permission.siteId === siteId;
        } else {
          // we must check either at the site or location level. If neither were provided, then throw an error
          throw new Error("You must provide either a siteId or a locationId or both.");
        }

        // Perform check for application root level permission
        // if the user permission.siteId is null, that means it's a permission set at the highest point in the location tree for that application.
        // The highest location is considered the "application root level". So we check to see if the permission.locations array includes the applicationId param
        // to see if the user has access at the application root level of the application we are asking to check.
        if (
          !hasAccess &&
          permission.siteId === null &&
          permission.locationIds.includes(applicationId)
        ) {
          hasAccess = true;
        }

        return hasAccess;
      });

      if (match) {
        // As the user only needs one of the provided permissionTypeIds, we should eject on the first permission that matches.
        matchingPermission = match;
        break;
      }
    }

    if (!matchingPermission && config.environment === "localdev") {
      const application = Object.values(applications).find(
        (a) => a.applicationId === applicationId
      );
      /* eslint-disable no-console */
      console.groupCollapsed(
        `[${application?.name ?? "Unknown Application"}] Permission check failed`
      );
      console.log("applicationId:", applicationId);
      console.log("permissions:", permissions);
      console.log("siteId:", siteId);
      console.log("skipSiteLocationCheck:", skipSiteLocationCheck);
      console.log("locationId:", locationId);
      console.groupEnd();
      /* eslint-enable no-console */
    }

    return !!matchingPermission;
  };

  fetchUserProfile = async () => {
    try {
      const response = await fetch(`${config.API["common-data"]}me`, {
        headers: {
          Authorization: await this.getAccessToken()
        }
      });

      this.userProfile = (await response.json()).data as UserProfile;
    } catch (error) {
      throw new Error("Failed to fetch user Profile");
    }
  };

  private setupListeners = () => {
    Hub.listen("auth", async (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        this.fetchUserProfile();

        MessageBus.publish("auth.signin", { success: true });
      }

      if (payload.event === "signOut") {
        this.signOut();
      }

      if (payload.event === "configured") {
        try {
          await Auth.currentSession();
          await this.fetchUserProfile();

          this.initialized = true;
          this.isAuthenticated = true;

          MessageBus.publish("auth.signin", { success: true });
        } catch (error) {
          MessageBus.publish("auth.error", { error });
          this.isAuthenticated = false;
          if (error === "No current user") {
            this.signIn();
          } else if (window.location.pathname !== "/not-found") {
            window.location.href = "/not-found";
          }
        }
      }
    });
  };

  static init = () => {
    type WindowWithAuth = typeof window & { Auth?: Authentication };

    const typedWindow: WindowWithAuth = window;

    if (!typedWindow.Auth) {
      typedWindow.Auth = new Authentication();
    }

    return typedWindow.Auth;
  };
}

export default Authentication.init();
