import { config } from "@nutrien-operations/config";

import { ApiInstance } from "../api-instance";

import { ManualEntryApi } from "./manual-entry";

// Authenticated API connection wrapper for the Online Log Sheets Api
export class OnlineLogSheetsApi {
  protected _instance: ApiInstance | null = null;

  constructor() {
    const prefixUrl = config.API["online-log-sheets"];
    this._instance = new ApiInstance(prefixUrl);
  }

  getInstance = () => this._instance;

  static ManualEntry = ManualEntryApi;
}

export const onlineLogSheetsApi = new OnlineLogSheetsApi().getInstance();
