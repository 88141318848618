import { useQuery, UseQueryOptions } from "react-query";
import { groupsByLocationId } from "./query-cache";
import { CommonDataApi, GroupListResponse, HTTPError } from "../../..";

function selectSortedByGroupName(response: GroupListResponse) {
  if (!response) {
    return undefined;
  }

  return {
    ...response,
    data: response.data.sort((a, b) =>
      a.groupName > b.groupName ? 1 : b.groupName > a.groupName ? -1 : 0
    )
  };
}

/**
 *  Hook to get groups at a location
 */
export const useGetGroupsByLocationId = (applicationId: string, locationId: string) => {
  const queryOptions: UseQueryOptions<GroupListResponse, HTTPError> = {
    queryFn: () => CommonDataApi.Groups.getByLocationId(applicationId, locationId),
    queryKey: groupsByLocationId(applicationId, locationId),
    select: selectSortedByGroupName,
    enabled: !!locationId
  };
  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  return {
    groups: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};
