import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer, useSnackbar } from "@packages/theme-mui-v5";
import { FormGroup, Typography, FormControlLabel, Checkbox } from "@mui/material";
import {
  Group,
  useAddUsersToGroups,
  useGetGroupsByLocationId,
  useGetGroupsByUserId,
  useGetPersonById,
  useRemoveUserFromGroups
} from "@packages/service-api";
import useLocalState from "../../hooks/useLocalState";

const ManageUser = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams<{ id: string }>();
  const { applicationId, selectedLocationId, siteId } = useLocalState();

  const { person: user } = useGetPersonById(id);
  const [selectedGroup, updateSelectedGroup] = React.useState<string[]>([]);
  const { addGroupMembers, loading: addUserLoading } = useAddUsersToGroups();
  const { removeGroupMembers, loading: removeMemberLoading } = useRemoveUserFromGroups();
  const { groups, loading } = useGetGroupsByLocationId(applicationId, selectedLocationId);
  const { groups: userGroup, loading: userGroupLoading } = useGetGroupsByUserId(
    applicationId,
    selectedLocationId,
    siteId,
    id
  );

  const onClose = () => {
    navigate("/admin/auth/users");
  };

  React.useEffect(() => {
    if (userGroup.length && !userGroupLoading) {
      const ids = userGroup.map((group) => group.groupId);
      updateSelectedGroup(ids);
    }
  }, [userGroup, userGroupLoading]);

  const handleSave = async () => {
    try {
      const added = [];
      const removed = [];
      userGroup.forEach((item) => {
        if (!selectedGroup.find((groupId) => groupId === item.groupId)) {
          removed.push(item.groupId);
        }
      });
      selectedGroup.forEach((groupId) => {
        if (!userGroup.find((item) => groupId === item.groupId)) {
          added.push(groupId);
        }
      });
      if (added.length) {
        await addGroupMembers({ groupIds: added, members: [id] });
      }
      if (removed.length) {
        await removeGroupMembers({ groupIds: removed, members: [id] });
      }
      enqueueSnackbar("Group members updated successfully.", {
        variant: "success"
      });

      onClose();
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const onGroupSelection = (checked: boolean, item: string) => {
    if (checked) {
      updateSelectedGroup([...selectedGroup, item]);
    } else {
      const updatedGroup = selectedGroup.filter((groupId) => groupId !== item);
      updateSelectedGroup(updatedGroup);
    }
  };

  return (
    <Drawer
      title="Manage User"
      contentTitle={`Manage User | ${user?.firstName || ""} ${user?.lastName || ""} (${
        user?.userName || ""
      })`}
      onClose={onClose}
      hideContent={loading || userGroupLoading}
      loading={loading || userGroupLoading || addUserLoading || removeMemberLoading}
      actions={[
        {
          text: "Save",
          action: handleSave,
          disabled: addUserLoading || removeMemberLoading
        },
        { text: "Cancel", action: onClose }
      ]}
    >
      {!!groups.length && (
        <FormGroup>
          <Typography variant="subtitle2">Update groups</Typography>
          {groups.map((data: Group) => {
            const checked = selectedGroup.some((groupId: string) => groupId === data.groupId);
            return (
              <FormControlLabel
                label={data.groupName}
                key={`item-${data.groupId}`}
                control={
                  <Checkbox
                    color="primary"
                    checked={checked}
                    onChange={() => onGroupSelection(!checked, data.groupId)}
                  />
                }
              />
            );
          })}
        </FormGroup>
      )}
    </Drawer>
  );
};

export default ManageUser;
