import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { Operation, compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import {
  TagWeightsListResponse,
  TagWeights,
  TagWeightsResponse
} from "../../types/accelerator/tag-weights";
import { escapeCharacters } from "../../utils";
import { SearchApiParams } from "../../types/datagrid-search";

/**
 *  Get TagWeights
 * @param {string} searchTerm
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<TagWeightsListResponse>}
 */
const searchTagWeights = async ({
  searchTerm,
  filter,
  sortModel,
  options
}: SearchApiParams<TagWeights>): Promise<TagWeightsListResponse> => {
  const from = options.from || 0;
  const size = options.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: [],
        filter: []
      }
    },
    sort: sortModel
  };

  if (searchTerm) {
    searchBody.query.bool.must = {
      multi_match: {
        query: escapeCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["*"]
      }
    };
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          terms: { [`${key}.keyword`]: value }
        });
      } else if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  return await acceleratorApi.post("tagWeights/search", searchBody);
};

/**
 * Get TagWeights By ID
 *
 * @param tagWeightsId: @ String
 *
 * @return {Promise<TagWeightsResponse>}
 */
const getTagWeightsById = (tagWeightsId: string): Promise<TagWeightsResponse> => {
  return acceleratorApi.get<TagWeightsResponse>(`tagWeights/${tagWeightsId}`);
};

/**
 * Search TagWeights By ID
 *
 * @param tagWeightsId: @ String
 *
 * @return {Promise<TagWeightsListResponse>}
 */
export const searchTagWeightsById = async (
  tagWeightsId: string
): Promise<TagWeightsListResponse> => {
  const searchBody: SearchRequest = {
    from: 0,
    size: 1,
    query: {
      bool: {
        filter: [
          {
            term: { [`id.keyword`]: tagWeightsId }
          }
        ]
      }
    }
  };
  return acceleratorApi.post("tagWeights/search", searchBody);
};

/**
 * Create TagWeights
 *
 * @param TagWeights: @type Partial<TagWeights>
 *
 * @return {Promise<TagWeights>}
 */
const createTagWeights = (tagWeights: Partial<TagWeights>): Promise<TagWeightsResponse> => {
  return acceleratorApi.post<Partial<TagWeights>, TagWeightsResponse>("tagWeights", tagWeights);
};

/**
 * Patch TagWeights
 * @param previousValue: @type TagWeights
 * @param nextValue: @type Partial<TagWeights>
 *
 * @return {Promise<TagWeightsResponse>}
 */
const patchTagWeights = async (
  previousValue: TagWeights,
  nextValue: Partial<TagWeights>
): Promise<TagWeightsResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return await acceleratorApi.patch<Operation[], TagWeightsResponse>(
    `tagWeights/${previousValue.id}`,
    patchUpdates
  );
};

/**
 * Delete Asset
 *
 * @param tagWeightsId: @ String
 *
 * @return {Promise<void>}
 */
const deleteTagWeights = (tagWeightsId: string): Promise<void> => {
  return acceleratorApi.delete(`tagWeights/${tagWeightsId}`);
};

export const TagWeightsApi = {
  searchTagWeights,
  searchTagWeightsById,
  getTagWeightsById,
  createTagWeights,
  patchTagWeights,
  deleteTagWeights
};
