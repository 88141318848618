import { CssBaseline } from "@mui/material";
import { lightTheme } from "./theme/theme";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

export type ThemeProviderProps = {
  children: React.ReactNode;

  /**
   * Pass in the local instance MUIThemeProvider to handle theme overrides for direct imports from `@mui/material`.
   *
   * @deprecated Used for theming direct imports from `@mui/material`.
   * This is bad because it creates a theme for each instance of material ui.
   * Instead, importing directly from `@mui/material` should be discouraged / blocked.
   */
  provider?: typeof MUIThemeProvider;
};

export function ThemeProvider({ children, provider: LocalThemeProvider }: ThemeProviderProps) {
  const themeChildren = (
    <>
      <CssBaseline />
      <main>{children}</main>
    </>
  );

  return (
    <MUIThemeProvider theme={lightTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {LocalThemeProvider ? (
          <LocalThemeProvider theme={lightTheme}>{themeChildren}</LocalThemeProvider>
        ) : (
          themeChildren
        )}
      </LocalizationProvider>
    </MUIThemeProvider>
  );
}
