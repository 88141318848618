import { config } from "@nutrien-operations/config";

import { ApiInstance } from "../api-instance";
import { SchemasApi } from "./schemas";
import { ItemsApi } from "./items";

export class ListOfValuesApi {
  protected _instance: ApiInstance | null = null;

  constructor() {
    const prefixUrl = config.API["list-of-values"];
    this._instance = new ApiInstance(prefixUrl);
  }

  getInstance = () => this._instance;

  static Items = ItemsApi;
  static Schemas = SchemasApi;
}

export const listOfValuesApi = new ListOfValuesApi().getInstance();
