import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import {
  NameplateLabelsListResponse,
  NameplateLabelsDocumentResponse,
  NameplateLabel
} from "../../types/accelerator";
import { escapeCharacters, urlPathWithQueryParams } from "../../utils";
import { SearchApiParams } from "../../types";
/**
 * Get Nameplate Labels
 *
 * @return {Promise<NameplateLabelsListResponse>}
 */
export const getNameplateLabels = async (): Promise<NameplateLabelsListResponse> => {
  return await acceleratorApi.get(
    urlPathWithQueryParams(`assetNameplateLabels`, { page: 0, size: 1000 })
  );
};

/**
 * Get Nameplate Label By ID
 *
 * @param nameplateLabelId: @ String
 *
 * @return {Promise<NameplateLabelsDocumentResponse>}
 */
export const getNameplateLabelById = async (
  nameplateLabelId: string
): Promise<NameplateLabelsDocumentResponse> => {
  return acceleratorApi.get(`assetNameplateLabels/${nameplateLabelId}`);
};

/**
 * Create Nameplate Label
 *
 * @param nameplateLabel: @ Partial<NameplateLabel>
 *
 * @return {Promise<NameplateLabelsDocumentResponse>}
 */
export const createNameplateLabel = async (
  nameplateLabel: Partial<NameplateLabel>
): Promise<NameplateLabelsDocumentResponse> => {
  return acceleratorApi.post("assetNameplateLabels", nameplateLabel);
};

/**
 * Patch Nameplate Label
 * @param previousValue: @type NameplateLabel
 * @param nextValue: @type Partial<NameplateLabel>
 *
 * @return {Promise<AssetResponse>}
 */
export const patchNameplateLabel = (
  previousValue: NameplateLabel,
  nextValue: Partial<NameplateLabel>
): Promise<NameplateLabelsDocumentResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch(`assetNameplateLabels/${previousValue.id}`, patchUpdates);
};

/**
 * Delete Nameplate Label
 *
 * @param nameplateLabelId: @ String
 *
 * @return {Promise<void>}
 */
export const deleteNameplateLabel = async (nameplateLabelId: string): Promise<void> => {
  await acceleratorApi.delete(`assetNameplateLabels/${nameplateLabelId}`);
};

/**
 * Search Nameplate Labels
 * @return {Promise<NameplateLabelsListResponse>}
 */
export const searchAssetNameplateLabels = async ({
  searchTerm,
  filter,
  sortModel,
  options
}: SearchApiParams<NameplateLabel>): Promise<NameplateLabelsListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: []
      }
    },
    sort: sortModel
  };

  if (searchTerm !== "") {
    (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
      multi_match: {
        query: escapeCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["*"]
      }
    });
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (value || value === null) {
        (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
          match: { [key]: value as string }
        });
      }
    });
  }

  const response = await acceleratorApi.post<SearchRequest, NameplateLabelsListResponse>(
    "assetNameplateLabels/search",
    searchBody
  );

  return response;
};

export const NameplateLabelsApi = {
  get: getNameplateLabels,
  getById: getNameplateLabelById,
  create: createNameplateLabel,
  update: patchNameplateLabel,
  delete: deleteNameplateLabel,
  search: searchAssetNameplateLabels
};
