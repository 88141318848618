import { SnackbarProvider as Provider, closeSnackbar } from "notistack";
import { useMediaQuery } from "../../hooks";
import { IconButton } from "@mui/material";
import "./snackbar-styles.css";
import { ApplicationIcon } from "../icons";

interface SnackbarProvider {
  children: React.ReactNode;
}

const SnackbarProvider = (props: SnackbarProvider) => {
  const { smUp } = useMediaQuery();
  return (
    <Provider
      classes={{
        containerRoot: "snackbar-container-root"
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: smUp ? "center" : "center"
      }}
      action={(key) => (
        <IconButton onClick={() => closeSnackbar(key)} sx={{ color: "#fff" }}>
          <ApplicationIcon size="large" name="close" />
        </IconButton>
      )}
    >
      {props.children}
    </Provider>
  );
};

export default SnackbarProvider;
