import { createTheme, lighten, ThemeOptions } from "@mui/material/styles";
import { PickerComponents } from "@mui/x-date-pickers/themeAugmentation";
import { LabComponents } from "@mui/lab/themeAugmentation";
// import breakpoints from "./breakpoints";
import typography from "./typography";
import materialPalette from "./materialPalette";
import mainThemeArguments from "./mainThemeArguments";
import darkThemePalette from "./darkThemePalette";
// import darkThemeArguments from "./darkThemeArguments";

const baseTheme: ThemeOptions & PickerComponents & LabComponents = {
  // breakpoints,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow:
            "0px 9px 12px rgba(0, 0, 0, 0.035), 0px 3px 16px rgba(0, 0, 0, 0.03), 0px 5px 6px rgba(0, 0, 0, 0.05)"
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          fontWeight: 400,
          color: "#333333",
          fontSize: 14
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: "#333333"
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        // variant: "standard",
        // InputLabelProps: { shrink: true },
        sx: { mb: 2 }
      },
      styleOverrides: {
        root: {
          "& .MuiInput-input": {
            fontWeight: 400,
            color: "#333333",
            fontSize: 14
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: { zIndex: 1600 }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: { zIndex: 1600 },
        root: {
          "& .MuiInput-root": {
            paddingBottom: 4
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          "&.MuiDialogTitle-root": {
            fontWeight: 600,
            color: "#333333",
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em"
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormLabel-asterisk": {
            fontWeight: 600,
            color: "#F24965"
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: 3
        }
      }
    },
    MuiTableHead: {
      defaultProps: {
        sx: {
          "& .MuiTableCell-head": {
            fontWeight: 600
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "unset"
        }
      }
    },
    MuiDatePicker: {
      defaultProps: {
        // InputAdornmentProps: {
        //   // sx: (theme) => ({
        //   //   "& > .MuiButtonBase-root": {
        //   //     color: theme.palette.primary.main
        //   //   }
        //   // })
        // }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          backgroundColor: lighten(materialPalette.warning.light, 0.5),
          color: "#333334",
          "&> .MuiAlert-icon": {
            color: lighten(materialPalette.warning.dark, 0.4)
          }
        },
        standardError: {
          backgroundColor: lighten(materialPalette.error.light, 0.5),
          color: "#333334",
          "&> .MuiAlert-icon": {
            color: lighten(materialPalette.error.dark, 0.4)
          }
        }
      }
    }
  },
  typography
};

const lightThemeOptions: ThemeOptions = {
  ...baseTheme,
  palette: {
    mode: "light",
    ...materialPalette
  }
};

export const lightTheme = createTheme(lightThemeOptions, mainThemeArguments);

const darkThemeOptions: ThemeOptions = {
  ...baseTheme,
  palette: { mode: "dark", ...darkThemePalette }
};

export const darkTheme = createTheme(darkThemeOptions);
