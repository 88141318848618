import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import {
  ColDef,
  GridReadyEvent,
  FilterChangedEvent,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  SizeColumnsToContentStrategy,
  SortChangedEvent,
  ExcelExportParams
} from "ag-grid-community";
import { LicenseManager } from "ag-grid-enterprise";

import {
  BooleanRenderer,
  CellLoadingRenderer,
  CustomLoadingOverlay,
  DateRenderer,
  HeaderRenderer
} from "./CustomRender";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./index.css";
import { useUserProfile } from "@packages/service-api";
import useStickyHeader from "./useStickyHeader";
import { AutoHeight } from "@packages/theme-mui-v5";
import { AgGridToolbar } from "./AgGridToolbar";
import { debounce } from "@packages/utils";
import { columnTypes } from "./utils";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-051202}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Agrium_Inc}_is_granted_a_{Multiple_Applications}_Developer_License_for_{10}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{23_January_2025}____[v3]_[01]_MTczNzU5MDQwMDAwMA==75dbac0d74f136b844a005ab73946201"
);

export interface AGGridProps<T> extends AgGridReactProps<T> {
  isServersidePagination: boolean; // if you wanted to set serverside pagination with default config
  gridRef?: React.Ref<AgGridReact<T>>;
  autoSizeAllColumns?: boolean;
  supressAutoRowHeight?: boolean;
  supressAutoWidth?: boolean;
  allowCustomHeader?: boolean;
  disableStickyHeader?: boolean;
  loading?: boolean;
  enableDynamicHeight?: boolean;
  minHeight?: number;
  offsetHeight?: number;
  // Enable quick search
  enableQuickSearch?: boolean;
  // Enable CSV export
  enableCsvExport?: boolean;
  // Optional toolbar component
  toolbarRightComponent?: ReactElement;
  toolbarLeftComponent?: ReactElement;
  // Multi cell editing
  multiCellEditingRenderer?: ReactElement;
  autoSizeAllColumnsOnDenseMode?: boolean;
  supressAutoSizeAllColumnsOnDataUpdate: boolean;
  csvExportFileName?: string;
  excelExportFileName?: string;
  enableExcelExport?: boolean;
  excelExportParams?: ExcelExportParams;
  enableHeaderHighlightOnCellFocus: boolean;
  onQuickFilterChange?: (searchTerm: string) => void;
}

export const CACHE_BLOCK_SIZE = 100; // ag grid default size

function DataGrid<T>(props: AGGridProps<T>) {
  const {
    gridOptions,
    isServersidePagination,
    columnDefs,
    components,
    rowData,
    defaultColDef,
    gridRef,
    serverSideDatasource,
    autoSizeAllColumns,
    supressAutoRowHeight,
    allowCustomHeader,
    supressAutoWidth,
    disableStickyHeader,
    loading,
    enableDynamicHeight,
    minHeight,
    offsetHeight,
    enableCsvExport,
    enableQuickSearch,
    toolbarRightComponent,
    toolbarLeftComponent,
    multiCellEditingRenderer,
    autoSizeAllColumnsOnDenseMode,
    supressAutoSizeAllColumnsOnDataUpdate,
    csvExportFileName,
    enableHeaderHighlightOnCellFocus,
    excelExportFileName,
    enableExcelExport,
    excelExportParams,
    onQuickFilterChange,
    ...rest
  } = props;

  const [grid, setGrid] = useState<GridReadyEvent<T>>();

  const userProfile = useUserProfile();
  const isDenseMode = userProfile?.preferences?.ui?.gridViewMode === "dense";
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  useStickyHeader(grid, disableStickyHeader);

  const rowHeight = useMemo(() => (isDenseMode ? 25 : 42), [isDenseMode]);

  const updatedGridOptions: AgGridReactProps = useMemo(() => {
    return {
      // Suppress column virtualisation as it's not working properly with auto column width
      suppressColumnVirtualisation: true,
      enableRangeSelection: true,
      domLayout: enableDynamicHeight ? "normal" : "autoHeight",
      rowSelection: "multiple",
      suppressRowClickSelection: false,
      headerHeight: 50,
      suppressMenuHide: true,
      allowContextMenuWithControlKey: true,
      copyHeadersToClipboard: false,
      reactiveCustomComponents: true,
      suppressDragLeaveHidesColumns: true,
      ...(isServersidePagination && {
        rowModelType: "serverSide",
        pagination: true,
        cacheBlockSize: CACHE_BLOCK_SIZE
      }),
      rowHeight,
      paginationAutoPageSize: true,
      animateRows: false,
      suppressBrowserResizeObserver: true,
      cellFlashDuration: 1000,
      autoSizePadding: 3,
      columnTypes,
      ...(!isServersidePagination && { groupDefaultExpanded: -1 }),
      ...gridOptions
    };
  }, [gridOptions, isServersidePagination, isDenseMode, enableDynamicHeight, rowHeight]);

  const updatedComponents = useMemo(() => {
    return {
      ...(allowCustomHeader && { agColumnHeader: HeaderRenderer }),
      BooleanRenderer,
      DateRenderer,
      CellLoadingRenderer,
      ...components
    };
  }, [components, allowCustomHeader]);

  const updatedDefaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
      sortable: !isServersidePagination,
      menuTabs: [],
      suppressHeaderMenuButton: true,
      ...defaultColDef
    };
  }, [defaultColDef, isDenseMode, isServersidePagination]);

  const updateColumnWidth = (params = grid) => {
    if (!params || supressAutoWidth) return;

    if (isDenseMode || autoSizeAllColumnsOnDenseMode) {
      if (autoSizeAllColumns) {
        params.api?.autoSizeAllColumns();
      }
    } else {
      params.api?.sizeColumnsToFit();
    }
  };

  const onFilterChanged = useCallback(
    (params: FilterChangedEvent) => {
      if (allowCustomHeader) {
        params.api?.refreshHeader();
      }

      props.onFilterChanged && props.onFilterChanged(params);
    },
    [allowCustomHeader]
  );

  const onSortChanged = useCallback(
    (params: SortChangedEvent) => {
      if (allowCustomHeader) {
        params.api?.refreshHeader();
      }
      props.onSortChanged && props.onSortChanged(params);
    },
    [allowCustomHeader]
  );

  const onGridReady = useCallback((params: GridReadyEvent<T>) => {
    // Set grid API for internal use as Ref
    setGrid(params);
    props?.onGridReady && props.onGridReady(params);
  }, []);

  useEffect(() => {
    if (!grid) return;

    if (!supressAutoRowHeight) {
      grid.api.forEachNode((rowNode) => {
        rowNode.setRowHeight(rowHeight);
      });
      grid.api.onRowHeightChanged();
    }
  }, [rowHeight, grid, supressAutoRowHeight]);

  useEffect(() => {
    if (loading) {
      grid?.api?.showLoadingOverlay();
    } else {
      grid?.api?.hideOverlay();
    }
  }, [loading, grid]);

  const onCellFocused = useCallback(
    (params) => {
      if (enableHeaderHighlightOnCellFocus) {
        params.api?.refreshHeader();
      }

      props.onCellFocused && props.onCellFocused(params);
    },
    [enableHeaderHighlightOnCellFocus]
  );

  const handleExcelExport = useCallback(
    (params) => {
      params?.api?.exportDataAsExcel({
        fileName: `${excelExportFileName}.xlsx`,
        sheetName: excelExportFileName,
        ...excelExportParams
      });
    },
    [excelExportFileName]
  );

  const getContextMenuItems = useCallback(
    (params) => {
      const fileExport = [];
      if (enableExcelExport) {
        fileExport.push({
          name: "Excel Export Selected Rows",
          action: handleExcelExport,
          icon: '<span class="ag-icon ag-icon-save" role="presentation"></span>'
        });
      }
      if (enableCsvExport) {
        fileExport.push({
          name: "CSV Export Selected Rows",
          action: () =>
            params.api.exportDataAsCsv({ onlySelected: true, fileName: csvExportFileName }),
          icon: '<span class="ag-icon ag-icon-save" role="presentation"></span>'
        });
      }
      return [
        "copy",
        "copyWithHeaders",
        "separator",
        {
          name: "Copy Selected Rows",
          action: () => params.api.copySelectedRowsToClipboard({ includeHeaders: false }),
          icon: '<span class="ag-icon ag-icon-copy" unselectable="on" role="presentation"></span>'
        },
        {
          name: "Copy Selected Rows With Headers",
          action: () => params.api.copySelectedRowsToClipboard({ includeHeaders: true }),
          icon: '<span class="ag-icon ag-icon-copy" unselectable="on" role="presentation"></span>'
        },
        "separator",
        ...fileExport,
        "separator",
        "autoSizeAll",
        {
          name: "SizeToFit All Columns",
          action: () => params.api.sizeColumnsToFit()
        }
      ];
    },
    [grid, enableExcelExport, csvExportFileName, excelExportFileName, enableCsvExport]
  );

  const getRowHeight = useCallback((): number | undefined | null => rowHeight, [rowHeight]);

  const autoSizeStrategy = useMemo<
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy
  >(() => {
    if (supressAutoWidth) return;
    return {
      type:
        (autoSizeAllColumnsOnDenseMode || isDenseMode) && autoSizeAllColumns
          ? "fitCellContents"
          : "fitGridWidth",
      skipHeader: false
    };
  }, [autoSizeAllColumns, supressAutoWidth, isDenseMode, autoSizeAllColumnsOnDenseMode]);

  useEffect(() => {
    if (
      grid &&
      !supressAutoSizeAllColumnsOnDataUpdate &&
      !isServersidePagination &&
      rowData?.length
    ) {
      setTimeout(() => {
        updateColumnWidth();
      }, 1);
    }

    const handleResizeDebounced = debounce(() => updateColumnWidth(), 300);

    if (!isDenseMode) {
      window.addEventListener("resize", handleResizeDebounced);
    }
    return () => {
      window.removeEventListener("resize", handleResizeDebounced);
    };
  }, [
    rowData,
    columnDefs,
    grid,
    supressAutoWidth,
    isDenseMode,
    autoSizeAllColumnsOnDenseMode,
    autoSizeAllColumns,
    supressAutoSizeAllColumnsOnDataUpdate
  ]);

  const toolbar = (
    <AgGridToolbar
      grid={grid}
      onQuickFilterChange={onQuickFilterChange}
      csvExportFileName={csvExportFileName}
      isServersidePagination={isServersidePagination}
      enableCsvExport={enableCsvExport}
      enableQuickSearch={enableQuickSearch}
      toolbarRightComponent={toolbarRightComponent}
      toolbarLeftComponent={toolbarLeftComponent}
      excelExportFileName={excelExportFileName}
      enableExcelExport={enableExcelExport}
      excelExportParams={excelExportParams}
      multiCellEditingRenderer={multiCellEditingRenderer}
    />
  );

  const dataGrid = (
    <div
      style={containerStyle}
      className={`datagrid-container ${
        isServersidePagination || serverSideDatasource ? "normal-height" : "auto-height"
      } ${isDenseMode ? "dense-mode" : "normal-mode"}`}
    >
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact<T>
          ref={gridRef}
          autoSizeStrategy={autoSizeStrategy}
          {...(!supressAutoRowHeight ? { getRowHeight } : {})}
          {...updatedGridOptions}
          serverSideDatasource={serverSideDatasource ?? null}
          getContextMenuItems={getContextMenuItems}
          onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
          onCellFocused={onCellFocused}
          onSortChanged={onSortChanged}
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={updatedDefaultColDef}
          components={updatedComponents}
          loadingOverlayComponent={CustomLoadingOverlay}
          {...rest}
        />
      </div>
    </div>
  );

  return (
    <>
      {toolbar}
      {enableDynamicHeight ? (
        <AutoHeight minHeight={minHeight} offsetHeight={offsetHeight}>
          {dataGrid}
        </AutoHeight>
      ) : (
        dataGrid
      )}
    </>
  );
}

DataGrid.defaultProps = {
  isServersidePagination: false,
  autoSizeAllColumns: true,
  autoSizeAllColumnsOnDenseMode: false,
  gridOptions: {},
  components: {},
  defaultColDef: {},
  supressAutoRowHeight: false,
  supressAutoSizeAllColumnsOnDataUpdate: false,
  supressAutoWidth: false,
  allowCustomHeader: false,
  loading: false,
  enableDynamicHeight: true,
  enableHeaderHighlightOnCellFocus: false,
  csvExportFileName: "export",
  excelExportFileName: "export"
};

export default DataGrid;
