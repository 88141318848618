import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { Operation, compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import {
  AssetTemplate,
  AssetTemplateListResponse,
  AssetTemplateDocumentResponse,
  AssetPersonaTemplateDocumentResponse,
  AssetPersonaTemplateListResponse,
  CloneEmptyTemplate
} from "../../types/accelerator/asset-templates";
import { AssetSubTypeListResponse, AssetTypeListResponse, SearchApiParams } from "../../types";
import { escapeCharacters } from "../../utils";

/**
 * Search Templates
 * @return {Promise<AssetTemplateListResponse>}
 */
export const searchTemplates = async ({
  searchTerm,
  filter,
  sortModel,
  options
}: SearchApiParams<AssetTemplate>): Promise<AssetTemplateListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: []
      }
    },
    sort: sortModel || {
      "assetProperty.name.keyword": { order: "asc", unmapped_type: "string" }
    }
  };

  if (searchTerm) {
    (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
      multi_match: {
        query: escapeCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["*"]
      }
    });
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (value || value === null) {
        (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
          match: { [key]: value as string }
        });
      }
    });
  }

  return await acceleratorApi.post<SearchRequest, AssetTemplateListResponse>(
    "templates/search",
    searchBody
  );
};

/**
 * Get Templates
 * @return {Promise<AssetTemplateListResponse>}
 */
export const searchPersonaAssetTemplates = async ({
  searchTerm,
  filter,
  sortModel,
  options
}: SearchApiParams<AssetTemplate>): Promise<AssetTemplateListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;
  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: [],
        filter: []
      }
    },
    sort: sortModel || {
      "template.assetProperty.name.keyword": { order: "asc", unmapped_type: "string" }
    }
  };

  if (searchTerm) {
    (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
      multi_match: {
        query: escapeCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["*"]
      }
    });
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      const objKey = key === "personaId" ? `${key}.keyword` : `template.${key}.keyword`;
      if (value || value === null) {
        (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
          term: { [`${objKey}`]: value }
        });
      }
    });
  }

  return await acceleratorApi.post<SearchRequest, AssetTemplateListResponse>(
    "personaTemplates/search",
    searchBody
  );
};

/**
 * Patch Template
 *
 * @param previousValue: @type TemplateType
 * @param nextValue: @type Partial<TemplateType>
 *
 * @return {Promise<AssetTemplateDocumentResponse>}
 */
export const patchTemplate = async (
  previousValue: AssetTemplate,
  nextValue: Partial<AssetTemplate>
) => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch<Operation[], AssetTemplateDocumentResponse>(
    `templates/${previousValue.templateId || previousValue.id}`,
    patchUpdates
  );
};

/**
 * Get Template  By ID
 *
 * @param templateId: @ String
 *
 * @return {Promise<AssetTemplateDocumentResponse>}
 */
export const getTemplateById = async (
  templateId: string
): Promise<AssetTemplateDocumentResponse> => {
  return acceleratorApi.get<AssetTemplateDocumentResponse>(`templates/${templateId}`);
};

/**
 * search Template By ID
 *
 * @param templateId: @ String
 *
 * @returns  {Promise<AssetTemplateListResponse>}
 */
export const searchTemplateById = async (
  templateId: string
): Promise<AssetTemplateListResponse> => {
  const searchBody: SearchRequest = {
    from: 0,
    size: 1,
    query: {
      bool: {
        filter: [
          {
            term: { [`id.keyword`]: templateId }
          }
        ]
      }
    }
  };
  return await acceleratorApi.post("templates/search", searchBody);
};

/**
 * search Persona Template By ID
 *
 * @param templateId: @ String
 *
 * @returns  {Promise<AssetPersonaTemplateListResponse>}
 */
export const searchPersonaTemplateById = async (
  templateId: string
): Promise<AssetPersonaTemplateListResponse> => {
  const searchBody: SearchRequest = {
    from: 0,
    size: 1,
    query: {
      bool: {
        filter: [
          {
            term: { [`id.keyword`]: templateId }
          }
        ]
      }
    }
  };
  return await acceleratorApi.post("personaTemplates/search", searchBody);
};

/**
 * Get Persona Template By ID
 *
 * @param templateId: @ String
 *
 * @returns  {Promise<AssetPersonaTemplateDocumentResponse>}
 */
export const getPersonaTemplateById = async (
  templateId: string
): Promise<AssetPersonaTemplateDocumentResponse> => {
  return acceleratorApi.get<AssetPersonaTemplateDocumentResponse>(`personaTemplates/${templateId}`);
};

/**
 * Get Empty assetTypes
 *
 * @returns  {Promise<AssetTypeListResponse>}
 */
export const getEmptyAssetTypes = async (): Promise<AssetTypeListResponse> => {
  return acceleratorApi.get(`templates/empty/assetTypes`);
};

/**
 * clone template
 *
 * @returns  {Promise<void>}
 */
export const cloneEmptyTemplate = async (ids: CloneEmptyTemplate): Promise<void> => {
  return acceleratorApi.put(`templates/clone`, ids);
};

/**
 * Get Empty assetTypes subType
 *
 * @param assetTypeId: @ String
 *
 * @returns  {Promise<AssetSubTypeListResponse>}
 */
export const getEmptyAssetSubTypes = async (
  assetTypeId: string
): Promise<AssetSubTypeListResponse> => {
  return acceleratorApi.get(`templates/empty/assetTypes/${assetTypeId}/assetSubTypes`);
};

/**
 * Create Template
 *
 * @param template: @ Partial<Template>
 *
 * @return {Promise<Template>}
 */
export const createTemplate = async (
  template: Partial<AssetTemplate> | Partial<AssetTemplate>[]
): Promise<AssetTemplateDocumentResponse | AssetTemplateListResponse> => {
  return acceleratorApi.post<
    Partial<AssetTemplate> | Partial<AssetTemplate>[],
    Promise<AssetTemplateDocumentResponse | AssetTemplateListResponse>
  >(`templates${Array.isArray(template) ? "/bulk" : ""}`, template);
};

/**
 * Patch Persona Template
 *
 * @param previousValue: @type TemplateType
 * @param nextValue: @type Partial<TemplateType>
 *
 * @return {Promise<AssetTemplateDocumentResponse>}
 */
export const patchPersonaTemplate = async (
  previousValue: AssetTemplate,
  nextValue: Partial<AssetTemplate>
) => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch<Operation[], AssetTemplateDocumentResponse>(
    `personaTemplates/${previousValue.id}`,
    patchUpdates
  );
};

/**
 * Delete Template
 *
 * @param templateId: @ String
 *
 * @return {Promise<void>}
 */
export const deleteTemplate = async (templateId: string): Promise<void> => {
  await acceleratorApi.delete(`templates/${templateId}`);
};

/**
 * Delete Persona Template
 *
 * @param templateId: @ String
 *
 * @return {Promise<void>}
 */
export const deletePersonaTemplate = async (templateId: string): Promise<void> => {
  await acceleratorApi.delete(`personaTemplates/${templateId}`);
};

export const TemplatesApi = {
  create: createTemplate,
  delete: deleteTemplate,
  search: searchTemplates,
  getById: getTemplateById,
  update: patchTemplate,
  searchById: searchTemplateById,
  getEmptyAssetTypes,
  getEmptyAssetSubTypes,
  clone: cloneEmptyTemplate,

  // TODO move these to apis/accelerator/asset-persona-templates ?
  getPersonaTemplateById,
  patchPersonaTemplate,
  deletePersonaTemplate,
  searchPersonaAssetTemplates,
  searchPersonaTemplateById
};
