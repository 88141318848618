import { useTheme } from "@mui/material/styles";
import { useMediaQuery as useMedia } from "@mui/material";

export const useMediaQuery = () => {
  const theme = useTheme();
  return {
    smUp: useMedia(theme.breakpoints.up("sm")),
    mdUp: useMedia(theme.breakpoints.up("md")),
    lgUp: useMedia(theme.breakpoints.up("lg")),
    smDown: useMedia(theme.breakpoints.down("sm")),
    mdDown: useMedia(theme.breakpoints.down("md")),
    lgDown: useMedia(theme.breakpoints.down("lg"))
  };
};
