import * as React from "react";
import AutoComplete from "../../components/AutoComplete";
import { useNavigate } from "react-router-dom";
import { Drawer, useSnackbar } from "@packages/theme-mui-v5";
import { FormGroup, Typography, FormControlLabel, Checkbox, Divider } from "@mui/material";
import {
  Group,
  People,
  useAddUsersToGroups,
  useGetGroupsByLocationId,
  useGetGroupsByUserId
} from "@packages/service-api";
import useLocalState from "../../hooks/useLocalState";

const AddUsersToGroup = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedUser, updateSelectedUser] = React.useState<People>(null);
  const [selectedGroup, updateSelectedGroup] = React.useState<string[]>([]);
  const { addGroupMembers, loading } = useAddUsersToGroups();
  const { applicationId, selectedLocationId, siteId } = useLocalState();
  const { groups } = useGetGroupsByLocationId(applicationId, selectedLocationId);
  const { groups: userGroup, loading: userGroupLoading } = useGetGroupsByUserId(
    applicationId,
    selectedLocationId,
    siteId,
    selectedUser?.personId
  );

  const onClose = () => {
    navigate("/admin/auth/users");
  };

  const handleSave = async () => {
    try {
      await addGroupMembers({ members: [selectedUser.personId], groupIds: selectedGroup });

      enqueueSnackbar("Group members updated successfully.", {
        variant: "success"
      });

      onClose();
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const onChange = (data) => {
    updateSelectedUser(data);
  };

  const onGroupSelection = (checked: boolean, item: string) => {
    if (checked) {
      updateSelectedGroup([...selectedGroup, item]);
    } else {
      const updatedGroup = selectedGroup.filter((groupId) => groupId !== item);
      updateSelectedGroup(updatedGroup);
    }
  };

  return (
    <Drawer
      contentTitle="Add User"
      title="Add User"
      onClose={onClose}
      loading={loading || userGroupLoading}
      maxWidth={550}
      width={550}
      actions={[
        {
          text: "Add user",
          action: handleSave,
          disabled: !selectedGroup.length
        },
        { text: "Cancel", action: onClose }
      ]}
    >
      <FormGroup>
        <Typography variant="subtitle2">User</Typography>
        <AutoComplete onChange={onChange} multiple={false} />
        {!!groups.length && selectedUser && (
          <>
            <Divider sx={{ ml: -2, mr: -2, mb: 2, mt: 1 }} />
            <Typography variant="subtitle2">Select groups</Typography>
            {groups.map((data: Group) => {
              const checked = selectedGroup.some((groupId: string) => groupId === data.groupId);
              const isGroupMember = userGroup.some((group) => group.groupId === data.groupId);
              return (
                <FormControlLabel
                  label={data.groupName}
                  key={`item-${data.groupId}`}
                  disabled={isGroupMember}
                  control={
                    <Checkbox
                      color="primary"
                      checked={checked || isGroupMember}
                      onChange={() => onGroupSelection(!checked, data.groupId)}
                    />
                  }
                />
              );
            })}
          </>
        )}
      </FormGroup>
    </Drawer>
  );
};

export default AddUsersToGroup;
