import { config } from "@nutrien-operations/config";

import { ApiInstance } from "../api-instance";

import { CalendarApi } from "./calendar";

export class SafeProductionCalendarApi {
  protected _instance: ApiInstance | null = null;

  constructor() {
    const prefixUrl = config.API["safe-production-calendar"];
    this._instance = new ApiInstance(prefixUrl);
  }

  getInstance = () => this._instance;

  static Calendar = CalendarApi;
}

export const safeProductionCalendarApi = new SafeProductionCalendarApi().getInstance();
