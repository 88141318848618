import { useEffect, useMemo } from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from "react-router-dom";

import * as InternalSentry from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/integrations";
import { Integration } from "@sentry/types";

import { config, release } from "@nutrien-operations/config";
import deepMerge from "deepmerge";
import { beforeSend } from "./hooks";

export const initializeSentry = (
  applicationName: string,
  options: InternalSentry.BrowserOptions = {}
) => {
  if (config.environment !== "localdev" && !config.Sentry.dsn) {
    //eslint-disable-next-line no-console
    console.warn("Unable to initialize Sentry. DSN configuration is missing.");
  }

  const defaultOptions: InternalSentry.BrowserOptions = {
    sampleRate: parseFloat(config.Sentry.sampleRate || "0"),
    autoSessionTracking: true,
    environment: config.environment,
    replaysOnErrorSampleRate: parseFloat(config.Sentry.replayErrorSampleRate || "0"),
    maxValueLength: 600,

    integrations: [
      InternalSentry.reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      captureConsoleIntegration({
        levels: ["error"]
      })
    ]
  };

  const mergedOptions = deepMerge(defaultOptions, options);

  InternalSentry.init({
    ...mergedOptions,
    dsn: config.Sentry.dsn,
    release: `nutrien-operations-monorepo@${release}`,
    beforeSend,
    integrations: [
      ...(mergedOptions.integrations as Integration[]),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ]
  });

  InternalSentry.setTag("application", applicationName);
};

export const useSentry = () => {
  return useMemo(() => Sentry, []);
};

export const Sentry = InternalSentry;
